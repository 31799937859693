import { useEffect, useState, useContext } from 'react';
import AvicTable from './table';
import { axios } from '../../../../../utils/axios';
import { showAlert } from '../../../../../utils/alerts';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';
const AvicSetup = ({ activeSubTab, activeTab, setLogsModal }) => {
  const [data, setData] = useState([]);
  const { crNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const { getIntCrUserRoleMappings, userRoleMappings } = useContext(CostingRequestsContext);

  useEffect(() => {
    const fetchMappings = async () => {
      await getIntCrUserRoleMappings();
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    if (
      activeTab === 'costConditionCreate' &&
      activeSubTab === 'avicSetup' &&
      userRoleMappings.length > 0
    ) {
      fetch();
    }
  }, [activeTab, activeSubTab, userRoleMappings]);

  const getAllowedCountry = () => {
    try {
      let countries = userRoleMappings
        .filter((role) => role.country && role.is_active && role.int_role?.name === 'GCC FP&A')
        .map((role) => role.country);

      countries = [...new Set(countries)];
      return countries;
    } catch (e) {
      console.log(e);
      return [];
    }
  };
  const checkActive = (status, country) => {
    const allowedCountries = getAllowedCountry();
    const res =
      String(status).toLowerCase() === 'in progress' && allowedCountries.includes(country);
    return res;
  };

  const transformData = (data, producing_country) => {
    const newData = data.map((ele) => {
      ele.manually_closed = ele?.status === 'Manually Closed';
      ele.status = ele?.status === 'Manually Closed' ? 'Closed' : ele?.status;
      ele.sku = ele?.int_cr_sku_selector?.sku;
      ele.sku_desc = ele?.int_cr_sku_selector?.sku_description;
      ele.payloadId = ele?.IntCrConditionRecordLogs?.[0]?.id;
      ele.accessAllowed = checkActive(ele?.status, producing_country);
      ele.sales_org = ele?.IntCrConditionRecordLogs?.[0]?.sales_org;
      ele.distribution_channel = ele?.IntCrConditionRecordLogs?.[0]?.distribution_channel;
      ele.condition_type = ele?.IntCrConditionRecordLogs?.[0]?.condition_type;
      ele.key_combination = ele?.IntCrConditionRecordLogs?.[0]?.key_combination;
      ele.costPerHL =
        parseFloat(ele?.IntCrConditionRecordLogs?.[0]?.cost_per_hl || 0).toFixed(4) || 0;
      ele.costPerHundred =
        parseFloat(ele?.IntCrConditionRecordLogs?.[0]?.cost_per_100hl || 0).toFixed(2) || 0;
      ele.currency = ele?.IntCrConditionRecordLogs?.[0]?.currency_key;
      ele.uom = ele?.IntCrConditionRecordLogs?.[0]?.unit_of_measure;
      ele.container_unit = ele?.IntCrConditionRecordLogs?.[0]?.per;
      ele.valid_from = moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_from).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_from).format('DD-MM-YYYY')
        : null;
      ele.valid_to = moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_to).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.valid_to).format('DD-MM-YYYY')
        : null;
      ele.fpna_gcc_sme = ele?.costing_ticket_assigned?.name || '';
      ele.completedOn = moment(ele?.IntCrConditionRecordLogs?.[0]?.completed_on).isValid()
        ? moment(ele?.IntCrConditionRecordLogs?.[0]?.completed_on).format('DD-MM-YYYY')
        : '';
      ele.fpnaName = ele?.costing_ticket_assigned?.name;
      return ele;
    });
    return newData;
  };

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/costing-request/cost-condition-create/${crNumber}`);
      const apiData = transformData(
        response?.data?.data?.steps,
        response?.data?.data?.producingCountry?.producing_country,
      );
      setData(apiData);
    } catch (error) {
      console.log(error);
      showAlert(error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async (distribution_channel, condition_type, id, sales_org) => {
    setLoading(true);
    try {
      const payload = {
        distribution_channel: distribution_channel,
        condition_type: condition_type,
        condition_record_id: id,
      };
      const response = await axios.post(
        `/costing-request/step/cost-condition-retry/${crNumber}`,
        payload,
      );
      if (response?.data.success) {
        showAlert({ message: `AVIC Setup successfully in Sales Org ${sales_org}` }, 'success');
      } else {
        showAlert(
          { message: `AVIC Setup failed in Sales Org ${sales_org}. Please check logs` },
          'error',
        );
      }
    } catch (e) {
      showAlert(
        { message: `AVIC Setup failed in Sales Org ${sales_org}. Please check logs` },
        'error',
      );
    } finally {
      fetch();
      setLoading(false);
    }
  };
  const getLogsData = async (id) => {
    try {
      const api = `/costing-request/cost-condition-logs/${crNumber}?cost_condition_id=${id}`;
      const res = await axios.get(api);
      const logs = res?.data?.data.map((ele) => {
        ele.status = ele.bapi_status;
        ele.remark = ele.bapi_remark;
        return ele;
      });
      return Array.isArray(logs) ? logs : [];
    } catch (e) {}
  };
  return (
    <>
      <AvicTable
        handleRefresh={handleRefresh}
        data={data}
        loading={loading}
        setLogsModal={setLogsModal}
        getLogsData={getLogsData}
        fetch={fetch}
      />
    </>
  );
};
export default AvicSetup;
