import { Tooltip, Badge, Text } from '@mantine/core';
import { useState, useEffect, useMemo } from 'react';
import { axios } from '../../../../utils/axios';

export const RefBadge = ({ crNumber, activePlant }) => {
  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    const getSelectedRef = async () => {
      try {
        const { data } = await axios.get(
          `/costing-request/step/selected-ref-sku-selection/${crNumber}`,
        );
        setFetchedData(data);
      } catch (e) {
        console.error('Error fetching reference SKU:', e);
      }
    };

    if (crNumber) {
      getSelectedRef();
    }
  }, [crNumber]);

  // Memoized value to prevent unnecessary re-renders
  const refSku = useMemo(() => {
    if ((!fetchedData || fetchedData.length === 0) && activePlant) {
      return { sku: '--', sku_description: '--', prod_plant: '--' };
    }
    const primaryRef = fetchedData?.filter(
      (ele) => ele?.plant_data?.producing_plant === activePlant,
    );
    return {
      sku: primaryRef?.[0]?.sku_details?.sku || '--',
      sku_description: primaryRef?.[0]?.sku_details?.sku_description || '--',
      prod_plant: primaryRef?.[0]?.sku_details?.plant || '--',
    };
  }, [fetchedData, activePlant]);

  return (
    <Badge style={{ color: 'black' }}>
      <Text size={16}>
        Ref: {refSku.sku} | {refSku.prod_plant} | {refSku.sku_description}
      </Text>
    </Badge>
  );
};
