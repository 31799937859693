import { Button, Grid, Select, Space, Text } from '@mantine/core';
import { getValueByKey } from '../../../../utils/helpers';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';
import { useContext, useState } from 'react';
import { skuColumns } from '../../draft/table/skusTable';
import { plantColumns } from '../../draft/table/plantsTable';
import { PreviewTable } from '../../draft/table/previewTable';
import { StyledText } from '../../styles';
import { Row, Col } from 'react-bootstrap';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import { ProjectOverview } from '../projectOverview';
import moment from 'moment';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { AppLoader } from '../../../../components/common/loader';
import { showAlert } from '../../../../utils/alerts';

const infoItems = [
  {
    name: 'Costing Year',
    key: 'costing_year',
    required: true,
  },
  {
    name: 'Requester Name',
    key: 'user.name',
  },
  // {
  //   name: 'Requester Team',
  //   key: 'user.name',
  //   offset: 2,
  // },
  {
    name: 'Urgency',
    key: 'urgency',
    offset: 2,
  },
  {
    name: 'Type of Goods',
    key: 'types_of_good',
  },

  {
    name: 'Type of SKU',
    key: 'types_of_sku',
    offset: 2,
  },
  {
    name: 'Type of Request',
    key: 'type_of_request',
  },
  {
    name: 'Sales District of the Buying Country',
    key: 'sales_district_of_buying_country',
    offset: 2,
  },
  {
    name: 'Stock Taking Order Type',
    key: 'stock_taking_order_type',
  },

  {
    name: 'Any Additional Comments',
    key: 'comment',
    offset: 2,
  },
];

const Divider = () => (
  <Grid.Col span={12}>
    <Space h='sm' />
  </Grid.Col>
);

const Label = ({ children }) => (
  <>
    <StyledText fz='md' fw={500}>
      {children}{' '}
    </StyledText>
  </>
);

const SelectPlantButton = ({ label, offset, data }) => {
  const columns = label === 'Selected SKU' ? skuColumns : plantColumns(label.split(' ')[1]);

  return (
    <Grid.Col md={6}>
      <Grid align='center'>
        <Grid.Col span={4}>
          <Label>{label}</Label>
        </Grid.Col>

        <Grid.Col span={12}>
          <PreviewTable label={label} data={data} columns={columns} />
        </Grid.Col>
      </Grid>
    </Grid.Col>
  );
};

const costingYearOptions = [moment().format('YYYY'), moment().add(1, 'year').format('YYYY')];

export const Overview = () => {
  const { crNumber } = useParams();
  const { info, setInfo, tabsPermissions } = useContext(CostingRequestsContext);
  const [costingYear, setCostingYear] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await axios.post(`/costing-request/updateCostingYear/${crNumber}`, {
        costing_year: costingYear,
      });
      await tabsPermissions(crNumber);
      setInfo((prevValues) => {
        return {
          ...prevValues,
          costing_year: costingYear,
        };
      });
      showAlert({ message: 'Costing year saved successfully' }, 'success');
    } catch (e) {
      showAlert(e, 'error');
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const isSubmitDisabled = () => {
    return !costingYear || info.costing_year;
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        <Button
          className='next'
          onClick={onSubmit}
          variant='gradient'
          radius='md'
          size='md'
          fullWidth
          disabled={loading || isSubmitDisabled()}
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
        >
          {loading ? <AppLoader size='sm' /> : 'Submit'}
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <ContentPaper footer={!info.costing_year ? <Footer /> : null}>
        <ProjectOverview />
        <Space h='md' />
        <Grid>
          {infoItems.map((item, index) => {
            const required = item.required && !info[item.key];
            const color = required ? 'red' : '';

            return (
              <Grid.Col md={6}>
                <Grid>
                  <Grid.Col span={6}>
                    <Text fw={700} fz='sm' color={color}>
                      {item.name}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Text fz='sm' color={color}>
                      {required ? (
                        item.key === 'costing_year' ? (
                          <Select
                            label=''
                            onChange={setCostingYear}
                            size='sm'
                            placeholder='Costing Year'
                            data={costingYearOptions}
                            value={costingYear}
                            disabled={loading || info.costing_year}
                            style={{ width: '160px' }}
                          />
                        ) : (
                          'N/A'
                        )
                      ) : (
                        getValueByKey(info, item.key)
                      )}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            );
          })}

          <Divider />

          <SelectPlantButton label='Selected SKU' data={info?.['sku_data']} />
          <SelectPlantButton
            label='Select Production Plant'
            offset={2}
            data={info?.['producing_plant_data']}
          />
          <Divider />
          {/* <SelectPlantButton label='Selected Selling Plant' data={info?.['selling_plant_data']} /> */}
          <SelectPlantButton
            label='Selected Buying Plant'
            offset={2}
            data={info?.['buying_plant_data']}
          />
        </Grid>
      </ContentPaper>
    </>
  );
};
