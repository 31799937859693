import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';
import MantineTable from '../../../common/table/mantine';
import { rfsStatus } from '../../../constants';

export const RfsTable = ({ loading, data, csvFilename }) => {
  let columns = useMemo(
    () => [
      {
        header: 'Req ID',
        accessorKey: 'costing_request_id',
        size: 100,
        Cell: ({ row }) => {
          const { costing_request_id, status } = row.original;
          return (
            <Link
              to={
                ![rfsStatus.draft].includes(status.toLowerCase())
                  ? routes.costingRequests(costing_request_id).overview
                  : routes.costingRequests(costing_request_id).request
              }
            >
              {costing_request_id}
            </Link>
          );
        },
      },
      {
        header: 'RFS Number',
        id: 'rfs_number',
        accessorFn: (row) => row.rfs_number || 'N/A',
        size: 100,
        Cell: ({ row }) => {
          const info = row.original;
          const checkPegusus =
            info?.rfs_number &&
            (info?.rfs_number.includes('OS-') || info?.rfs_number.includes('PS-'));
          return info?.rfs_number ? (
            checkPegusus ? (
              <Link to={routes.project(info.rfs_number).root}>{info.rfs_number}</Link>
            ) : (
              <a
                href={`https://pegasus-abinbev.datastation.com/default/rfs/viewrfsoverview/${info?.rfs_number}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {info?.rfs_number}
              </a>
            )
          ) : (
            'N/A'
          );
        },
      },
      {
        header: 'SKU No',
        accessorKey: 'sku',
        size: 100,
        Cell: ({ row }) => row.original?.sku || 'N/A',
      },
      {
        header: 'SKU Description',
        accessorKey: 'sku_description',
        size: 250,
        Cell: ({ row }) => row.original?.sku_description || 'N/A',
      },
      {
        header: 'Requestor',
        accessorKey: 'userName',
      },
      {
        header: 'Submission Date',
        accessorKey: 'date',
        Cell: ({ row }) => row.original?.date || 'N/A',
      },
      {
        header: 'Type of request',
        accessorKey: 'type_of_request',
        Cell: ({ row }) => row.original?.type_of_request || 'N/A',
      },
      {
        header: 'Urgency',
        accessorKey: 'urgency',
        Cell: ({ row }) => row.original?.urgency || 'N/A',
      },
      {
        header: 'FSD Date',
        accessorKey: 'fsd_date',
        Cell: ({ row }) => row.original?.fsd_date || 'N/A',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        Cell: ({ row }) => row.original?.status || 'N/A',
      },
      {
        header: 'Steps In Progress',
        accessorKey: 'steps_in_progress',
        Cell: ({ row }) => row.original?.steps_in_progress || 'N/A',
      },
      {
        header: 'Production Plant',
        accessorKey: 'producing_plant',
        Cell: ({ row }) => row.original?.producing_plant || 'N/A',
      },
      {
        header: 'Selling Country',
        accessorKey: 'selling_country',
        Cell: ({ row }) => {
          const info = row.original;
          return info?.selling_country ? info.selling_country : 'N/A';
        },
      },
      {
        header: 'Buying Country',
        accessorKey: 'buying_country',
        Cell: ({ row }) => row.original?.buying_country || 'N/A',
      },
      {
        header: 'FP&A GCC SME',
        accessorKey: 'spoc_pricing_team',
        Cell: ({ row }) => row.original?.spoc_pricing_team || 'N/A',
      },
    ],
    [data],
  );

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={data}
        unique={'costing_request_id'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        enableExport={true}
        csvFilename={csvFilename}
        pageSize={10}
        enablePinning={true}
        initialState={{
          columnPinning: {
            left: ['costing_request_id', 'rfs_number', 'sku', 'sku_description'],
            right: [' '],
          },
          showColumnFilters: true,
        }}
      />
    </>
  );
};
