import moment from 'moment';

export const transformData = (data) => {
  const newData = data.map((ele) => {
    ele.version = '';
    ele.id = ele?.IntCrTpFinalizations?.[0]?.id;
    ele.control_number = ele?.IntCrTpFinalizations?.[0]?.tp_code;
    ele.manufacturing_entity_name = ele?.IntCrTpFinalizations?.[0]?.meta?.manufacturing_entity;
    ele.manufacturing_service = ele?.IntCrTpFinalizations?.[0]?.meta?.manufacturing_service;
    ele.manufacturing_plant = ele?.IntCrTpFinalizations?.[0]?.meta?.manufacturing_plant;
    ele.mu_percent = ele?.IntCrTpFinalizations?.[0]?.meta?.mu_perc;
    ele.updated_by = ele?.IntCrTpFinalizations?.[0]?.LogisticsFpNa_UpdatedBy?.name;
    ele.producing_zone = ele?.IntCrTpFinalizations?.[0]?.producing_zone;
    ele.producing_country = ele?.IntCrTpFinalizations?.[0]?.producing_country;
    ele.erp_sku_number = ele?.IntCrTpFinalizations?.[0]?.sku;
    ele.sku_name = ele?.IntCrTpFinalizations?.[0]?.sku_description;
    ele.sales_org = ele?.IntCrTpFinalizations?.[0]?.sales_org;
    ele.buying_country = ele?.IntCrTpFinalizations?.[0]?.buying_country;
    ele.buying_zone = ele?.IntCrTpFinalizations?.[0]?.meta?.buying_zone;
    ele.vplc = parseFloat(ele?.IntCrTpFinalizations?.[0]?.meta?.vplc_calc || 0).toFixed(4);
    ele.std_logistics_costs = parseFloat(ele?.IntCrTpFinalizations?.[0]?.stg_log || 0).toFixed(4);
    ele.leffe_royalties = parseFloat(
      ele?.IntCrTpFinalizations?.[0]?.meta?.leffe_royalities || 0,
    ).toFixed(4);
    ele.submitted_on = moment(ele?.IntCrTpFinalizations?.[0]?.updatedAt).isValid()
      ? moment(ele?.IntCrTpFinalizations?.[0]?.updatedAt).utc().startOf('day').format('DD-MM-YYYY')
      : null;
    ele.completed_on = moment(ele?.IntCrTpFinalizations?.[0]?.finalzedAt).isValid()
      ? moment(ele?.IntCrTpFinalizations?.[0]?.finalzedAt).utc().startOf('day').format('DD-MM-YYYY')
      : null;
    ele.brand = ele?.IntCrTpFinalizations?.[0]?.sku_brand;
    ele.fob = ele?.IntCrTpFinalizations?.[0]?.meta?.fob_calc;
    ele.vlc = ele?.IntCrTpFinalizations?.[0]?.vlc;
    ele.fixed_costs = ele?.IntCrTpFinalizations?.[0]?.meta?.fixed_cost;
    ele.dep = ele?.IntCrTpFinalizations?.[0]?.meta?.dep;
    ele.royalties = parseFloat(ele?.IntCrTpFinalizations?.[0]?.meta?.royalty || 0).toFixed(2);
    ele.std_mu = parseFloat(ele?.IntCrTpFinalizations?.[0]?.meta?.std_mu || 0).toFixed(2);
    ele.other_intangible_compensation =
      ele?.IntCrTpFinalizations?.[0]?.meta?.intangile_compensation;
    ele.incoterm = ele?.IntCrTpFinalizations?.[0]?.meta?.incoterm;
    ele.curr = ele?.IntCrTpFinalizations?.[0]?.meta?.currency_final;
    ele.total_tp = ele?.IntCrTpFinalizations?.[0]?.meta?.total_tp
      ? parseFloat(ele?.IntCrTpFinalizations?.[0]?.meta?.total_tp || 0).toFixed(2)
      : '0.00';
    ele.pack_type = ele?.IntCrTpFinalizations?.[0]?.sku_pack_type;
    ele.producing_country = ele?.IntCrTpFinalizations?.[0]?.producing_country;
    ele.vplc = parseFloat(ele?.IntCrTpFinalizations?.[0]?.meta?.vplc_calc || 0).toFixed(4);
    ele.fpna_logistics_sme = ele?.LogisticsFpNa_sme?.name;
    ele.fpna_gcc_sme = ele?.FPnA_sme?.name;
    ele.indicative_database = String(ele?.IntCrTpFinalizations?.[0]?.meta?.indicative_database)
      .toLowerCase()
      .split(',');
    ele.vic = parseFloat(ele?.IntCrTpFinalizations?.[0]?.meta?.vic_calc || 0).toFixed(4);
    ele.status = ele?.IntCrTpFinalizations?.[0]?.status;

    ele.interco_markup_in_perf = ele?.IntCrTpFinalizations?.[0]?.interco_markup_in_perf
      ? parseFloat(ele?.IntCrTpFinalizations?.[0]?.interco_markup_in_perf).toFixed(2)
      : '0.00';
    ele.interco_markup_out_perf = ele?.IntCrTpFinalizations?.[0]?.interco_markup_out_perf
      ? parseFloat(ele?.IntCrTpFinalizations?.[0]?.interco_markup_out_perf).toFixed(2)
      : '0.00';
    return ele;
  });
  return newData;
};
