import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Grid, Group, Text, Textarea } from '@mantine/core';
import { Checkbox } from '@mantine/core';
import { RfsContext } from '../../../contexts/RfsContext';
import { InfoCircle } from 'tabler-icons-react';
import { AppLoader } from '../../../components/common/loader';
import { AuthContext } from '../../../contexts/AuthContext';

export const ActionModal = ({
  actionModal,
  setActionModal,
  text = 'You have selected the manual closure option. This action cannot be undone once confirmed. Please check the checkbox and click on OK if you wish to proceed ',
  save,
  status_id,
  state,
  id,
  active,
  updateLoading = false,
  commentRequired = false,
}) => {
  const { loading, saveAsDraft } = useContext(RfsContext);
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState('');
  const [localLoading, setLocalLoading] = useState(false);

  const { userEmail } = useContext(AuthContext);

  useEffect(() => {
    if (actionModal.open) {
      setChecked(false);
      setValue('');
    }
  }, [actionModal.open]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setActionModal({ open: false, id: null });
    setValue('');
    setChecked(false);
    if (document.querySelector('.mantine-Tabs-body .mantine-Checkbox-input:checked'))
      document.querySelector('.mantine-Tabs-body .mantine-Checkbox-input:checked').checked = false;
  };

  const handleNext = async () => {
    const payload = {
      remark: value.substring(0, 250),
      user: userEmail,
      status_ids: status_id,
    };
    setLocalLoading(true);
    await save(payload, actionModal.id, state);
    setLocalLoading(false);
  };

  return (
    <>
      {status_id === actionModal.id && (
        <Modal
          className={'Invalid-modal ' + id}
          opened={actionModal?.open}
          onClose={() => handleClose()}
          title={'Disclaimer '}
        >
          <Grid>
            <Grid.Col span={12}>
              <Group noWrap className='alert-modal-group'>
                <InfoCircle color='black' size='7rem' />
                <Text size='sm'>{text}</Text>
              </Group>
            </Grid.Col>
            <Grid.Col span={12}>
              <Textarea
                label='Enter Comments'
                placeholder='Max 250 characters'
                value={value}
                onChange={(event) => setValue(event.currentTarget.value)}
                required
              />
              {value?.length > 249 && (
                <div className='red-custom-mantine-Textarea-error'>
                  250 Character limit reached, exceeding character would be truncated on saving.
                </div>
              )}
            </Grid.Col>
            <Grid.Col span={12}>
              <Checkbox
                label='I accept the terms and conditions above'
                name='confirmed'
                onChange={handleChange}
                checked={checked}
              />
            </Grid.Col>
            <Grid.Col span={4} offset={8}>
              <Button
                onClick={() => handleNext()}
                variant='gradient'
                gradient={{ from: '#e3af32', to: '#f4e00f' }}
                color='primary'
                fullWidth
                disabled={
                  !checked ||
                  loading ||
                  saveAsDraft ||
                  updateLoading ||
                  localLoading ||
                  (commentRequired && value.trim().length === 0)
                }
              >
                {!loading && !saveAsDraft && !updateLoading && !localLoading ? 'OK' : <AppLoader />}
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>
      )}
    </>
  );
};
