import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Tabs } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';

import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';
import { Context, stepsKeys } from '../context';

import { Table } from './table';
import { AttachmentsList } from '../../attachments/list';
import { ChildCRBanner, getPlantName } from '../helper';

export const CostComponent = ({ activeTab, activePlant, setActivePlant }) => {
  const { info, crUserPermissions } = useContext(CostingRequestsContext);
  const {
    info: vicInfo,
    initialLoading,
    setAttachmentModal,
    fetchAttachments,
    attachmentModal,
    downloadAttachment,
    deleteAttachment,
    resetAttachmentsModal,
    attachmentLoading,
    stepStatus,
    readOnly,
    setStepStatus,
    activePage,
    setPlantModal,
    getAttachmentsRequest,
    scopeUnlockedPlants,
  } = useContext(Context);
  const [data, setData] = useState([]);
  const [vsAttachmentsLoading, setVsAttachmentsLoading] = useState(false);
  const [vsAttachments, setVsAttachments] = useState([]);

  const { producing_plant_data = [], sku_data = [] } = info;

  useEffect(() => {
    if (
      ['vicSubmission', 'vicApproval'].includes(activeTab) &&
      sku_data.length &&
      producing_plant_data.length &&
      activePlant.id
    ) {
      const { producing_plant, id } = activePlant.id ? activePlant : producing_plant_data[0];
      resetAttachmentsModal();
      const hasAccess = crUserPermissions[String(activePlant)]?.['standard/transfer_pricing'].is_accessible
      hasAccess ? loadCcDetails(true, { producing_plant, id }) : loadCcDetails(false, { producing_plant, id })
      setActivePlant({ producing_plant, id });
    } else {
      setActivePlant({
        producing_plant: producing_plant_data?.[0]?.producing_plant || null,
        id: producing_plant_data?.[0]?.id || null,
      });
    }
  }, [activeTab, producing_plant_data, sku_data, activePlant.id]);

  const loadCcDetails = async (refresh = false, plant, loading = true) => {
    const vicDetails = await vicInfo(refresh, plant, loading);

    getVsAttachments(vicDetails?.[0]?.vic_submission_step_id);
    fetchAttachments(vicDetails?.[0]?.step_id);

    setStepStatus(vicDetails?.[0]?.status);
    setData(vicDetails);
  };

  const getVsAttachments = async (stepId) => {
    try {
      if (activeTab === 'vicApproval' && stepId) {
        setVsAttachmentsLoading(true);
        const { data } = await getAttachmentsRequest(stepId, stepsKeys.vicSubmission);
        setVsAttachments(data?.uploads || []);
      }
    } catch (e) {
    } finally {
      setVsAttachmentsLoading(false);
    }
  };

  const openUpladModal = () => {
    setAttachmentModal({
      ...attachmentModal,
      open: true,
      plantId: data?.[0]?.step_id,
      attachments: [],
    });
  };

  const handleRefresh = (plant) => {
    loadCcDetails(true, plant);
  };

  const rightButtons = [
    activePage === 'vicSubmission' && {
      button: (
        <Button variant='light' color='gray.6' onClick={() => handleRefresh(activePlant)}>
          <IconRefresh color='black' variant='filled' size={16} />
        </Button>
      ),
    },
    activePage === 'vicApproval' && {
      label: 'View Open Plants',
      onClick: () => {
        setPlantModal(true);
      },
    },
    {
      button: (
        <Button
          variant='gradient'
          color='dark'
          onClick={() => openUpladModal()}
          disabled={readOnly || (stepStatus || '').toLowerCase() !== 'in progress'}
        >
          Upload Attachment
        </Button>
      ),
    },
  ].filter((button) => button);

  return (
    <>
      {activeTab === 'vicApproval' && (
        <AttachmentsList
          title='VIC Submission'
          loading={vsAttachmentsLoading}
          attachments={vsAttachments}
          onDownload={() => () => {}}
          onRemove={() => () => {}}
          readOnly={true}
        />
      )}
      <AttachmentsList
        loading={attachmentLoading || initialLoading}
        attachments={attachmentModal?.existingAttachments || []}
        onDownload={(attachment) => downloadAttachment(attachment.name)}
        onRemove={(attachment) => deleteAttachment(attachment, activePlant.id)}
        readOnly={readOnly || (stepStatus || '').toLowerCase() !== 'in progress'}
      />

      <Tabs
        keepMounted={false}
        value={activePlant.id}
        onTabChange={(value) =>
          setActivePlant({
            ...activePlant,
            id: value,
            producing_plant: getPlantName(value, producing_plant_data),
          })
        }
        color='yellow'
      >
        <Tabs.List>
          {producing_plant_data.map(({ producing_plant, id }, index) => (
            <Tabs.Tab value={id} className='menu-tabs' key={index} disabled={initialLoading}>
              {producing_plant}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {producing_plant_data.map(({ id, producing_plant }, index) => {
          return (
            <Tabs.Panel value={id} key={index}>
              <Grid>
                {activeTab === 'vicApproval' && scopeUnlockedPlants[producing_plant] && (
                  <Grid.Col span={12}>
                    <ChildCRBanner
                      crNumber={scopeUnlockedPlants[producing_plant].child_costing_request_id}
                    />
                  </Grid.Col>
                )}
                <Grid.Col span={12}>
                  <div>
                    <Table
                      data={(data || []).filter((item) => item.pp_id === id)}
                      loading={initialLoading}
                      rightButtons={rightButtons}
                      activePlant={activePlant}
                    />
                  </div>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          );
        })}
      </Tabs>
    </>
  );
};
