import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Text } from '@mantine/core';
import { axios } from '../../../../utils/axios';
import MantineTable from '../../../../common/table/mantine';
import { useParams } from 'react-router-dom';
import { Status } from '../../styles';
import { CostingRequestsContext } from '../../../../contexts/CostingRequest';

export const BomCreation = ({ setLogsModal, activeTab }) => {
  const { crNumber } = useParams();
  const { startManualClosure, crUserPermissions } = useContext(CostingRequestsContext);
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState([]);

  useEffect(() => {
    if (activeTab === 'bomCreation') fetch();
  }, [activeTab]);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/costing-request/step/costing-bom-check/${crNumber}`);
      const data = response.data.data.map((item) => {
        const status = (item.status || '').toLowerCase();
        return {
          id: item.id,
          sku_number: item.int_cr_sku_selector.sku,
          sku_description: item.int_cr_sku_selector.sku_description,
          producing_plant: item.int_cr_producing_plant.producing_plant,
          bomAvailable: ['manually closed', 'closed'].includes(status) ? 'Yes' : 'No',
          status:
            status === 'not started'
              ? 'Not Started'
              : ['manually closed', 'closed'].includes(status)
              ? 'Completed'
              : 'Pending',
          manually_closed: status === 'manually closed',
          logs: item.IntCrStepStatusLogs,
          assignedTo: item.costing_ticket_assigned?.name || 'N/A',
          spoc: item.step_assigned?.name || 'N/A',
        };
      });
      setSkus(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'sku_number',
        header: 'SKU No',
      },
      {
        accessorKey: 'sku_description',
        header: 'SKU Description',
      },
      {
        accessorKey: 'producing_plant',
        header: 'Production Plant',
      },
      {
        accessorKey: 'bomAvailable',
        header: 'Costing BOM Available?',
      },
      {
        id: 'status',
        header: 'Status',
        Cell: ({ row }) => <Status status={row.original.status} />,
      },
      {
        header: 'Action',
        id: 'action',
        disableSortBy: true,
        Cell: ({ row }) => {
          const readOnly =
            !crUserPermissions?.[row.original.producing_plant]?.['standard/transfer_pricing']
              .is_accessible;
          return ['Pending', 'Not Started'].includes(row.original.status) ? (
            <Checkbox
              onChange={() => {
                startManualClosure({ open: true, id: row.original.id, callback: fetch });
              }}
              defaultChecked={false}
              label='Confirm and Close'
              disabled={['Closed', 'Not Started'].includes(row.original.status) || readOnly}
            />
          ) : row.original.manually_closed ? (
            <Text>Manual Closure</Text>
          ) : (
            <Text>Automatic Closure</Text>
          );
        },
      },
      {
        id: 'logs',
        header: 'Logs',
        Cell: ({ row }) => (
          <Button
            color='dark'
            onClick={() =>
              setLogsModal({
                open: true,
                logs: row.original.logs,
                type: 'Costing BOM Creation Check',
              })
            }
          >
            View Logs
          </Button>
        ),
      },
      {
        accessorKey: 'assignedTo',
        header: 'FP&A GCC SME',
      },
      {
        accessorKey: 'spoc',
        header: 'SMD SPOC',
      },
    ],
    [skus],
  );

  return (
    <>
      <MantineTable
        columns={columns}
        initialData={skus}
        unique={'sku'}
        enableRowSelection={false}
        loading={loading}
        showSelectedToggle={false}
        hideSelectColumn={true}
        showResetAll={false}
        enableExport={true}
        initialState={{
          sorting: [
            {
              id: 'sku_number',
              desc: false,
            },
          ],
        }}
        csvFilename={`costing_bom_creation_check_${moment().format('YYYY-MM-DD')}`}
        pageSize={10}
      />
    </>
  );
};
