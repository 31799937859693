import React, { useContext, useEffect, useState } from 'react';
import ContentPaper from '../../../../../components/layouts/rfs/Content';
import { Button, Grid, Space, Tabs } from '@mantine/core';
import { ProjectOverview } from '../../projectOverview';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AvicTable from '../table';
import { CustomTextArea } from '../../../../../common/inputs/CustomTextArea';
import { Provider, useAvic } from '../context';
import { AttachmentsProvider, stepsKeys, useCrAttachments } from '../../attachments/context';
import { AttachmentsList } from '../../attachments/list';
import { createPlantObject, getGeneralInfo, getPayload, hasPermission } from '../helper';
import { AppLoader } from '../../../../../components/common/loader';
import { CostingRequestsContext } from '../../../../../contexts/CostingRequest';

const Content = () => {
  const { crNumber } = useParams();
  const { fetchAvicPlants, plantLoading, submit, saveLoading } = useAvic();
  const { info, crUserPermissions } = useContext(CostingRequestsContext);
  const [plants, setPlants] = useState({});
  const { producing_plant_data = [], sku_data = [] } = info;
  const [activePlant, setActivePlant] = useState();
  const [footerData, setFooterData] = useState({});
  const [generalInfo, setGeneralInfo] = useState({
    skuId: null,
    stepId: null,
  });
  const {
    setAttachmentModal,
    fetchAttachments,
    attachmentModal,
    downloadAttachment,
    deleteAttachment,
    attachmentLoading,
    getAttachmentsRequest,
    setDisableUploads,
  } = useCrAttachments();
  const [avsAttachmentsLoading, setAvsAttachmentsLoading] = useState(false);
  const [avsAttachments, setAvsAttachments] = useState([]);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    if (Object.keys(crUserPermissions).length > 0 && producing_plant_data?.length > 0) {
      setActivePlant(producing_plant_data?.[0]?.producing_plant);
      fetch();
    }
  }, [crNumber, crUserPermissions, producing_plant_data]);

  const fetch = async () => {
    try {
      const data = await fetchAvicPlants('finalization');
      const info = data?.[0] || {};

      const plantObject = createPlantObject(info.plant_level);
      const isAccessible = hasPermission('avicFinalization', Object.values(crUserPermissions));

      setDisableUploads(false);
      setPlants(plantObject);
      setFooterData(info?.country_level?.[0] || {});
      setGeneralInfo(getGeneralInfo(info.plant_level));
      setReadOnly(info.status.toLowerCase() !== 'in progress' || !isAccessible);

      const stepId = info?.plant_level?.[0]?.step_id;
      if (stepId) {
        fetchStepAttachments(stepId, info.avic_submission_step_id);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchStepAttachments = (stepId, submissionStepId) => {
    getAvsAttachments(submissionStepId);
    fetchAttachments(stepId);
  };

  const openUpladModal = () => {
    setAttachmentModal({
      ...attachmentModal,
      open: true,
      plantId: generalInfo.stepId,
      attachments: [],
    });
  };

  const onSubmit = async (action) => {
    const payload = getPayload(Object.values(plants), footerData, generalInfo);
    await submit('finalization', action, payload);
    fetch();
  };

  const Footer = () => (
    <Row className='justify-content-md-end' md={4}>
      <Col md='auto'>
        <Button
          className='next'
          variant='gradient'
          radius='md'
          size='md'
          color='dark'
          fullWidth
          style={{
            width: '200px',
          }}
          onClick={() => onSubmit('save')}
          disabled={readOnly}
        >
          {saveLoading ? <AppLoader size='sm' variant='dark' /> : 'Save'}
        </Button>
      </Col>
      <Col md='auto'>
        <Button
          className='next'
          variant='gradient'
          radius='md'
          size='md'
          fullWidth
          style={{
            marginRight: '1rem',
            width: '200px',
          }}
          onClick={() => onSubmit('finalize')}
          disabled={readOnly}
        >
          {saveLoading ? <AppLoader size='sm' /> : 'Finalize AVIC'}
        </Button>
      </Col>
    </Row>
  );

  const handleChange = (value, name) => {
    const values = { ...footerData };
    values[name] = value;

    setFooterData(values);
  };

  const getAvsAttachments = async (stepId) => {
    try {
      setAvsAttachmentsLoading(true);
      const { data } = await getAttachmentsRequest(stepId, stepsKeys.avicSubmission);
      setAvsAttachments(data?.uploads || []);
    } catch (e) {
    } finally {
      setAvsAttachmentsLoading(false);
    }
  };

  return (
    <ContentPaper footer={<Footer />}>
      <ProjectOverview />
      <Space h='sm' />
      <Tabs value={activePlant} onTabChange={(value) => setActivePlant(value)} color='yellow'>
        <Tabs.List>
          {producing_plant_data.map(({ producing_plant }, index) => (
            <Tabs.Tab value={producing_plant} className='menu-tabs' key={index}>
              {producing_plant}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {producing_plant_data.map(({ producing_plant }, index) => {
          return (
            <Tabs.Panel value={producing_plant} key={index}>
              <AttachmentsList
                title='AVIC Submission'
                loading={avsAttachmentsLoading || plantLoading}
                attachments={avsAttachments}
                onDownload={() => () => {}}
                onRemove={() => () => {}}
                readOnly={true}
              />

              <AttachmentsList
                loading={attachmentLoading || plantLoading}
                attachments={attachmentModal?.existingAttachments || []}
                onDownload={(attachment) => downloadAttachment(attachment.name)}
                onRemove={(attachment) => deleteAttachment(attachment, generalInfo.stepId)}
                readOnly={readOnly}
              />

              <AvicTable
                data={plants[activePlant] ? { [activePlant]: plants[activePlant] } : {}}
                footerData={footerData}
                loading={plantLoading}
                upload={openUpladModal}
                setValues={setPlants}
                setFooterValues={setFooterData}
                disableUpload={!generalInfo.stepId || readOnly}
              />

              <Grid style={{ marginTop: '22px' }}>
                <Grid.Col span={12}>
                  <CustomTextArea
                    label={'Comment (If any)'}
                    maxLength={255}
                    minRows={4}
                    onChange={(value) => handleChange(value, 'comment')}
                    value={footerData?.comment || ''}
                  />
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          );
        })}
      </Tabs>
    </ContentPaper>
  );
};

const AvicFinalization = () => (
  <Provider>
    <AttachmentsProvider>
      <Content />
    </AttachmentsProvider>
  </Provider>
);

export default AvicFinalization;
